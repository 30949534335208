<div class="container-fluid bg-light">
    <div class="row">
        <div class="col-md-12 mx-auto hOnePageTitle">
            <h1 class="p-4 text-center">Delete Account</h1>
        </div>
    </div>
</div>
<div class="container single-page mb-5">
    <div class="row">
        <div class="col-md-10 py-5">
            <h4>Steps to delete your account</h4>
            <p>1. Download Vowpay mobile app which is free to download from Google Play or Apple App Stores.</p>
            <img  class="my-3" src="assets/images/googleplay_white.png"><span class="mx-2"></span> <img  class="my-2"  src="assets/images/appstore_white.png">
            <p>2. Login to your account using mobile number and password.</p>
            <p>3. Go to my account and setting.</p>
            <p>4. Click Delete acccount, enter mobile number and your account will be deleted. </p>
        </div>
    </div>
</div>