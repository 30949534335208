import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { FaqComponent } from './faq/faq.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { CookiepolicyComponent } from './cookiepolicy/cookiepolicy.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { DeleteaccountComponent } from './deleteaccount/deleteaccount.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { ResetpassworddoneComponent } from './resetpassworddone/resetpassworddone.component';
import { SignupemailverifiedComponent } from './signupemailverified/signupemailverified.component';
import { SignupemailnotverifiedComponent } from './signupemailnotverified/signupemailnotverified.component';
import { SignupemailverifyComponent } from './signupemailverify/signupemailverify.component';

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'aboutus', component: AboutusComponent},
  {path: 'contactus', component: ContactUsComponent},
  {path: 'faq', component: FaqComponent},
  {path: 'terms', component: TermsComponent},
  {path: 'privacypolicy', component: PrivacypolicyComponent},
  {path: 'cookiepolicy', component: CookiepolicyComponent},
  {path: 'disclaimer', component: DisclaimerComponent},
  {path: 'deleteaccount', component: DeleteaccountComponent},
  {path: 'customers/resetpassword', component: ResetpasswordComponent},
  {path: 'customers/resetpassworddone', component: ResetpassworddoneComponent},
  {path: 'customers/signupemailverify', component: SignupemailverifyComponent},
  {path: 'customers/signupemailverified', component: SignupemailverifiedComponent},
  {path: 'customers/signupemailnotverified', component: SignupemailnotverifiedComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
